<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">Subordinate Individual MPP</h3>
        <div>
          <el-select v-model="selectedEvalPeriod" @change="handleChange()" placeholder="Select Evaluation Period" size="mini">
            <el-option v-for="mpp in mppList" :key="mpp.id" :value="mpp.id" :label="mpp.name"></el-option>
          </el-select>
          <el-button style="margin-left:10px;" size="mini"  @click="sendMPP" :disabled="selectedEvalPeriod == ''">Send</el-button>
        </div>
      </div>
      <div>
        <el-row :gutter="24">
          <el-col :span="5" style="float: right;">
            <el-input
              v-model="employeeSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Employee"
            />
          </el-col>
        </el-row>
        <el-table
          :data="tableData.filter(data => !employeeSearchText || data.name.toLowerCase().includes(employeeSearchText.toLowerCase()))"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <!-- <el-table-column>
            <template slot="header" slot-scope="scope">
              <el-checkbox @click="hello(scope)" v-model="checkedAllMPP" :disabled="tableData.length === 0" label="Notify"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isChecked"></el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="name"
            label="Employee Name"
            >
          </el-table-column>
          <el-table-column
            prop="bp_id_name"
            label="Job Title"
            >
          </el-table-column>
          <el-table-column
            prop="bj_id_name"
            label="Rank"
            >
          </el-table-column>
          <!-- <el-table-column
            label="Set MPP"
            >
            <template slot="header" slot-scope="scope">
              <el-checkbox v-model="checkedAllMPP" @change="handleSelectSend($event, scope.row)" :disabled="tableData.length === 0"></el-checkbox>
              <span style="margin-left: 10px;">Set MPP</span>
            </template>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.set_mpp"></el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column
            label="Action"
            width="90"
            >
            <template slot="header" slot-scope="scope">
              <el-checkbox v-model="checkedAllSR" @click="hello(scope)" @change="handleSR($event)" :disabled="tableData.length === 0"></el-checkbox>
              <span style="margin-left: 10px;">Self Rate</span>
            </template>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_self_rate" true-label=1 false-label=0 @change="handleCheckbox($event, scope.row.id)"></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  mounted () {
    this.getMPP()
  },
  data () {
    return {
      multipleSelection: [],
      mppList: [],
      selectedEvalPeriod: '',
      employeeSearchText: '',
      checkedAllSR: false,
      tableData: [],
      checkedAllMPP: false
    }
  },
  // watch: {
  //   checkedAllMPP: {
  //     handler (value) {
  //       if (value) {
  //         this.tableData.forEach(e => {
  //           e.isChecked = true
  //         })
  //       } else {
  //         this.tableData.forEach(e => {
  //           e.isChecked = false
  //         })
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  methods: {
    // canSelectRow (row, index) {
    //   if (row.)
    //   console.log(row, index)
    // },
    // sendMPP () {
    //   var filtered = this.tableData.filter(e => e.isChecked === true)
    //   var mapped = filtered.map(e => e.id)
    //   if (mapped.length === 0) {
    //     this.$message({
    //       showClose: true,
    //       message: 'Please select at least one employee before sending!',
    //       type: 'error'
    //     })
    //   } else {
    //     var formSubmit = {}
    //     formSubmit.id = mapped
    //     formSubmit.hme_id = this.selectedEvalPeriod
    //     console.log(formSubmit)
    //     this.$http
    //       .post('mpp-send-to-employee', formSubmit)
    //       .then(res => {
    //         if (res.data.StatusCode) {
    //           this.$message({
    //             showClose: true,
    //             message: 'Form Submitted',
    //             type: 'success'
    //           })
    //           this.handleChange()
    //         } else {
    //           this.$message({
    //             showClose: true,
    //             message: res.data.body,
    //             type: 'error'
    //           })
    //         }
    //       })
    //   }
    // },
    sendMPP () {
      var formSubmit = {}
      formSubmit.hme_id = this.selectedEvalPeriod
      this.$http
        .post('mpp-send-to-employee', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.handleChange()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    handleSelectionChange (val) {
      var newFormat = val.map(e => e.id)
      this.multipleSelection = newFormat
    },
    handleSR (event) {
      const copy = JSON.parse(JSON.stringify(this.tableData))
      var formSubmit = {}
      console.log(event)
      if (event) {
        formSubmit.ids_allow = copy.map(value => value.id)
        formSubmit.ids_notallow = []
      } else {
        formSubmit.ids_allow = []
        formSubmit.ids_notallow = copy.map(value => value.id)
      }
      this.$http
        .post('mpp-employee-self-rate', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.handleChange()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    handleCheckbox (event, id) {
      var formSubmit = {}
      console.log(event, id)
      var index = this.tableData.findIndex(x => x.id === id)
      console.log(index)
      const copy = JSON.parse(JSON.stringify(this.tableData))
      var filteredAllowed = copy.filter(e => e.is_self_rate === '1')
      var filteredNotAllowed = copy.filter(e => e.is_self_rate === '0')
      var arrNotAllowed = filteredNotAllowed.map(value => value.id)
      var arrAllowed = filteredAllowed.map(value => value.id)
      console.log(arrNotAllowed)
      console.log(arrAllowed)
      if (event === '1') {
        arrNotAllowed.splice(index)
      }
      console.log(arrNotAllowed)
      console.log(arrAllowed)
      formSubmit.ids_allow = arrAllowed
      formSubmit.ids_notallow = arrNotAllowed
      this.$http
        .post('mpp-employee-self-rate', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.handleChange()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    getMPP () {
      this.$http
        .get('mpp-completed-evaluation-list')
        .then(res => {
          console.log(res)
          this.mppList = res.data.body
        })
    },
    handleChange () {
      this.$http
        .get('mpp-employee-list/' + this.selectedEvalPeriod)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.checkedAllSR = false
            this.tableData = []
          } else {
            res.data.body.forEach(e => {
              e.isChecked = false
            })
            if (!res.data.body.filter(e => e.is_self_rate === '0').length > 0) {
              this.checkedAllSR = true
            } else {
              this.checkedAllSR = false
            }
            this.tableData = res.data.body
          }
        })
    },
    hello (scope) {
      console.log(scope, 'scope')
    }
  }
}
</script>
